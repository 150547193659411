import React, { Component } from 'react';
import Types from './types'
import classNames from 'classnames'
import typewriter from '../images/typewriter.png'
import astronaut from '../images/astronaut.png'

const expandedDisplayTypes = [
  'snippet',
]

class SearchResult extends Component {
  constructor(props) {
    super(props);
    this.resultRef = React.createRef();
  }

  handleOnClick(event, result) {
    if (this.props.selected) {
      this.props.handleClick();
    }

    this.props.onClick(event, result);
  }

  render() {
    const { result, selected, active, inactive, value, isTouchDevice } = this.props

    let imageUrl = ""
    if (result.lede_image) {
      imageUrl = result.lede_image.url
    } else if (result.type === "person") {
      imageUrl = astronaut
    } else {
      imageUrl = typewriter
    }

    if (this.props.result.type === 'post') {
      this.props.result.type = 'article';
    }

    // TODO move common functionality like onMouseEnter, onMouseLeave, onClick and class names to here, as a wrapping element.

    const childComponent = expandedDisplayTypes.includes(result.type) ? (
			React.cloneElement(Types[result.type], {
				result: result,
        selected: selected,
        isSearchResult: true,
        value: value,
				onClick: this.props.onClick,
        onMouseEnter: this.props.onMouseEnter,
        onMouseLeave: this.props.onMouseLeave,
        active: active,
        inactive: inactive,
        isTouchDevice: isTouchDevice
			})
    ) : (
      <div
        className={classNames("result", {selected: selected, isTouch: isTouchDevice()})}
        key={result.id}
        onClick={(event) => this.handleOnClick(event, result)}
        onMouseEnter={this.props.onMouseEnter}
        onMouseLeave={this.props.onMouseLeave}
        ref={this.resultRef}
        >
				{imageUrl && (
					<div className={'result-featured-img'} style={{ background: `url('${imageUrl}') no-repeat center/contain` }}>
					</div>
        )}
        <div className={'result-information'}>
          <h3 dangerouslySetInnerHTML={{__html: result.title}}></h3>
          {/* <h3 dangerouslySetInnerHTML={{__html: result.title}}></h3> */}
          <h4 dangerouslySetInnerHTML={{__html: result.type}}></h4>
        </div>
			</div>
    )

    // In case we need type specific search results:
    // const content = React.cloneElement(Types[result.type], { post: result })
    return childComponent
  }
}

export default SearchResult
