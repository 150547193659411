import React, { Component } from 'react'

class PageTheatreManager extends Component {
	render() {
		const post = this.props.post

		return (
			<div className="theatre-manager">
				<div className='tm-hero-illustration'>
					<h1 className="header-extra-large">Ticketing that shines as bright as your stars</h1>
					<div className='subtitle'>Premium customization for the Artsman Theatre Manager CRM</div>
				</div>
				<div className="post-content" dangerouslySetInnerHTML={{ __html: post.content.rendered }} />
			</div>
		)
	}
}

export default PageTheatreManager
