import React, { Component } from 'react'
import iframeResizer from 'iframe-resizer'

class PageTheatreManagerV2 extends Component {
	componentDidMount() {
		iframeResizer.iframeResizer({}, '#form-embed')
	}
	render() {
		const post = this.props.post

		return (
			<div className="theatre-manager theatre-manager-v2">
				<div className="post-content" dangerouslySetInnerHTML={{ __html: post.content.rendered }} />
			</div>
		)
	}
}

export default PageTheatreManagerV2
