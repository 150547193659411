import React, { Component } from 'react'

class PageElevate extends Component {
	render() {
		const post = this.props.post

		return (
			<div className="elevate">
				<div className='elevate-hero-illustration'>
					<h1 className="header-extra-large"><strong>Elevate</strong> the<br /> Patron <strong>Experience</strong></h1>
					<p class="subtitle has-medium-font-size">Strategy | Websites | Ticketing | Donations | Accessibility</p>
				</div>
				<div className="post-content" dangerouslySetInnerHTML={{ __html: post.content.rendered }} />
			</div>
		)
	}
}

export default PageElevate
