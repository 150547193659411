import React, { Component } from 'react'
import Store from '../Store'
import Fuse from 'fuse.js'
import Cookies from 'js-cookie'
import classNames from 'classnames'
import LoadingSpinner from './LoadingSpinner';

// https://gist.github.com/jed/982883
const uuid = function(a){return a?((a^Math.random()*16)>>a/4).toString(16):([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g,uuid)};

const fuseOptions = {
  shouldSort: true,
  threshold: 0.2,
  location: 0,
  distance: 1000,
  maxPatternLength: 48,
  minMatchCharLength: 1,
  searchIndexFetched: false,
  searchFieldHasFocus: false,
  keys: [
    "title",
    "synonyms",
    "excerpt",
  ]
}

const keywords = [
  'About',
  'Work',
  'Journal',
  'Contact',
]

class Search extends Component {
  constructor(props) {
    super(props)

    if (typeof Cookies.get('keyword-session') === 'undefined') {
      Cookies.set('keyword-session', uuid());
    }

    this.state = {
      fuse: new Fuse([], fuseOptions),
      session: Cookies.get('keyword-session'),
      lastSearchTerm: '',
    }

    this.buttonRef = React.createRef();
    this.inputCover = React.createRef();
    this.searchField = React.createRef();

    onkeyup = this.handleKeyPress.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleKeywordChange = this.handleKeywordChange.bind(this)
    this.handleSearchFocus = this.handleSearchFocus.bind(this)
    this.handleKeyPress = this.handleKeyPress.bind(this)
    this.handleSearchSubmit = this.handleSearchSubmit.bind(this)
    this.handleButtonSubmit = this.handleButtonSubmit.bind(this)
    this.handleInputOnClick = this.handleInputOnClick.bind(this)
    this.handleClearContent = this.handleClearContent.bind(this)
  }

  componentDidMount() {
    // console.log("Search: componentDidMount")
    Store.getSearchIndex().then((searchIndex) => {
      // console.log("Got the search index")
      this.setState({
        fuse: new Fuse(searchIndex, fuseOptions),
        searchIndexFetched: true,
      }, () => {
        this.handleKeywordChange(this.props.searchValue)
      })
    })
  }

  componentDidUpdate(prevProps) {
    // console.log("Search: componentDidUpdate: current: ", this.props.searchValue, " previous: ", prevProps.searchValue)
    if (this.props.searchValue !== prevProps.searchValue && this.props.searchValue !== this.state.lastSearchTerm) {
      this.handleKeywordChange(this.props.searchValue)
    }
  }

  handleInputChange(e) {
    // console.log('Search: handleInputChange: ')
    this.handleKeywordChange(e.target.value)
  }

  handleKeywordChange(keyword) {
    console.log('Search: handleKeywordChange: ', keyword)
    this.setState({
      lastSearchTerm: keyword
    })
    if (!this.state.searchIndexFetched) {
      return {
        searchTerm: keyword ? keyword.slice(0, 32) : '',
        results: [],
        loading: true,
      }
    }
    
    let nextState = {
      searchTerm: keyword.slice(0, 32),
      results: []
    }

    if (!nextState.searchTerm || nextState.searchTerm === "") {
      nextState.results = []
    }
    else {
      const searchResults = this.state.fuse.search(nextState.searchTerm).slice()
      // Make a copy so that if we remove duplicates later on, they're not removed from the the index:
      // console.log('found these results: ', searchResults)
      nextState.results = JSON.parse(JSON.stringify(searchResults))
    }

    // If there's an exact keyword match, only show related results for that keyword:
    const exactKeywordMatch = nextState.results.find((result) => result.type === 'keyword' && result.title.toLowerCase() === nextState.searchTerm.toLowerCase())
    if (exactKeywordMatch && exactKeywordMatch.exact_match) {
      nextState.results = [exactKeywordMatch]
    }

    // Remove duplicate posts
    let seen = new Set()
    for (var resultIndex = 0; resultIndex < nextState.results.length; resultIndex++) {
      let result = nextState.results[resultIndex]
      if (result.type === 'keyword' && result.related) {
        for (var postIndex = 0; postIndex < result.related.length; postIndex++) {
          let post = result.related[postIndex]
          if (seen.has(post.id)) {
            result.related.splice(postIndex, 1)
            postIndex--
          }
          seen.add(post.id)
        }
      } else {
        if (seen.has(result.id)) {
          nextState.results.splice(resultIndex, 1)
          resultIndex--
        }
        seen.add(result.id)
      }
    }

    this.props.updateResults(nextState)

    fetch(`/wp-admin/admin-ajax.php?${
      [
        `action=insert_log`,
        `keyword=${nextState.searchTerm}`,
        `count=${nextState.results.length}`,
        `session_id=${this.state.session}`,
        `created_at=${(new Date()).toISOString()}`
      ].join('&')
    }`, {
      credentials: 'include',
    })
  }

  handleKeyPress(e) {
    if (e.key === 'Enter') {
      this.handleSearchSubmit(e.target.value);
    }

    if (e.code === 'Escape') {
      if (this.props.showingContent) {
        this.props.closeContentPane()
        this.searchField.current.focus()
      }
      else {
        this.props.handleClear()
      }
    }
  }

  handleTouchStart(event) {
    this.buttonRef.current.style.backgroundColor = "rgb(57, 74, 88)";
    this.buttonRef.current.style.color = "rgb(251, 248, 244)";
  }

  handleTouchEnd(event) {
    this.buttonRef.current.style.backgroundColor = "transparent";
    this.buttonRef.current.style.color = "rgb(57, 74, 88)";
  }

  handleButtonSubmit (e) {
    this.handleSearchSubmit(this.props.searchValue);
  }

  handleSearchFocus(e, value) {
    this.props.closeContentPane()
    this.setState({
      searchFieldHasFocus: true
    })
  }

  handleInputOnClick(e) {
    console.log('SINGLE');
    e.preventDefault()
    e.stopPropagation()
    window.scrollTo(0,0)
    this.handleSearchFocus()
    this.searchField.current.focus()
  }

  handleInputDoubleClick(e) {
    console.log('DOUBLE');
    this.searchField.current.select();
  }

  handleClearContent(value) {
    this.props.handleClearContent();
  }

  handleSearchSubmit(value) {
    this.props.updateBreadCrumbs(value)
  }

  handleClear() {
    this.searchField.current.focus()
    this.props.handleClear()
  }

  render() {
    const nav = this.props.device === 'desktop' ? (
      <React.Fragment>
      <div className="logo" onClick={this.handleClear.bind(this)}>Denim & Steel</div>
      <div className="small-logo" onClick={this.handleClear.bind(this)}></div>
      <div className="search-items">
        <h3 className="search-title" onClick={this.handleClear.bind(this)}>
          Let's start here
        </h3>
        <div className="navbar-items">
          <div className="input-cover desktop">
            <input
              value={this.props.searchValue}
              onChange={this.handleInputChange}
              onFocus={this.handleSearchFocus}
              onKeyPress={this.handleKeyPress}
              onSubmit={this.handleSearchSubmit}
              className="search-input"
              autoFocus={!this.props.showingContent}
              autoComplete="off"
              autoCorrect="off"
              autoCapitalize="off"
              spellCheck="false"
              ref={this.searchField}
            />
            {this.props.isLoading ?
              <div className="clear-content">
                <LoadingSpinner />
              </div>
              :
              this.props.searchValue.length > 0 &&
              <div className="clear-content" onClick={this.props.handleClearContent}>
                <div className="clear-logo"></div>
              </div>
            }
          </div>
          <button onClick={this.handleButtonSubmit} className="search-button">search</button>
        </div>
        <div className={`keywords`}>
          {keywords.map((keyword) => {
            return (<span onClick={this.handleKeywordChange.bind(this, keyword)} key={keyword}>{keyword}</span>)
          })}
          </div>
        </div>
        <div className={classNames("bread-crumbs", {ipad: this.props.isTouchDevice()})}>
          <div className="bread-crumbs-inner">
          {this.props.breadCrumbs.map((term) => {
            return (<span onClick={this.handleKeywordChange.bind(this, term)} key={term} className="term">{term}</span>)
          })}
          </div>
        </div>
      </React.Fragment>
    ) : (
      <div className={'mobile-nav'}>
        <div className="mobile-nav-inner">
          <div className="navbar-items">
            <div className="small-logo" onClick={this.props.handleClear}></div>
            <div className={classNames("input-cover", { 'has-focus': this.state.searchFieldHasFocus })} onClick={(e) => {this.handleInputOnClick(e)}} onDoubleClick={(e) => {this.handleInputDoubleClick(e)}} ref={this.inputCover}>
              <input
                value={this.props.searchValue}
                onChange={this.handleInputChange}
                onKeyPress={this.handleKeyPress}
                onSubmit={this.handleSearchSubmit}
                className="search-input"
                maxLength="18"
                placeholder="Let's start here"
                autoComplete="off"
                autoCorrect="off"
                autoCapitalize="off"
                spellCheck="false"
                ref={this.searchField}
              />
              {this.props.isLoading ?
              <div className="clear-content">
                <LoadingSpinner />
              </div>
              :
              this.props.searchValue.length > 0 &&
              <div className="clear-content" onClick={this.props.handleClearContent}>
                <div className="clear-logo"></div>
              </div>
            }
            </div>
            <button
              onClick={this.handleButtonSubmit}
              onTouchStart={(event) => this.handleTouchStart(event)}
              onTouchEnd={(event) => this.handleTouchEnd(event)}
              className="search-button-mobile"
              ref={this.buttonRef}>
              search
            </button>
          </div>
          <div className="keywords">
            {keywords.map((keyword) => {
              return (<span onClick={this.handleKeywordChange.bind(this, keyword)} key={keyword} className="link">{keyword}</span>)
            })}
          </div>
        </div>
      </div>
    )

    return (
      <div className="search">
        {nav}
      </div>
    )
  }
}

export default Search