import React, { Component } from 'react'

class Work extends Component {
	render() {
    const { post } = this.props

    return (
			<div className={'work'}>
				<h1 dangerouslySetInnerHTML={{__html: post.title.rendered}} />
				<div className="work-meta-list">
					{post.acf.client_name && (
						<div className="work-meta">
							<h3 className="title">Client</h3>
							{post.acf.client_name}
						</div>
					)}
					{post.acf.year && (
						<div className="work-meta">
							<h3 className="title">Year</h3>
							{post.acf.year}
						</div>
					)}
					{post.acf.collaborators && (
						<div className="work-meta">
							<h3 className="title">Collaborators</h3>
							{post.acf.collaborators.map((collaborator, index) => {
								return (<div key={index}>{collaborator.post_title}</div>)
							})}
						</div>
					)}
				</div>
				<div className="post-content" dangerouslySetInnerHTML={{__html: post.content.rendered}} />
			</div>
		)
  }
}

export default Work