import React, { Component } from 'react'
import classNames from 'classnames'

class EphemeraItem extends Component {
	constructor(props) {
		super(props)
    const ephemeraSize = 80

    let rowsAvailable = 9
    let colsAvailable = 9


    colsAvailable = Math.floor((window.innerWidth / 2) / ephemeraSize)
    rowsAvailable = Math.floor(window.innerHeight / ephemeraSize)

    let horizontalMargin = ((window.innerWidth / 2) - (colsAvailable * ephemeraSize)) / 2
    let verticalMargin = (window.innerHeight - (rowsAvailable * ephemeraSize)) / 2
		
    this.state = {
			top: Math.floor(Math.random() * rowsAvailable) * ephemeraSize + verticalMargin,
			left: Math.floor(Math.random() * colsAvailable) * ephemeraSize + horizontalMargin,
      color: Math.random() > 0.5 ? 'blue' : 'copper'
		}
	}
	
  render() {
    const { post, emphasize, deemphasize } = this.props
    const { top, left, color } = this.state

    let ephemeraContent
    if (post.type === "image") {
      ephemeraContent = (<img src={post.thumbnail} alt={post.title} />)
    } else if (post.type === "video") {
      let videoEmbed = post.video
      // Add autoplay and other parameters:
      const regex = /src="(.+?)"/g
      const matches = post.video.match(regex)
      if (matches.length > 0) {
        const src = matches[0]
        let newSrc = src
        newSrc = newSrc.substr(0, newSrc.length - 1) + "&version=3&autoplay=1&showinfo=0&controls=0&loop=1​&mute=1" + newSrc.substr(newSrc.length - 1)
        videoEmbed = videoEmbed.replace(src, newSrc)
      }
      ephemeraContent = (<div dangerouslySetInnerHTML={{__html: videoEmbed}} className="embed-container" />)
    }

    return (
      <div className={classNames("ephemera-item", post.type, color, {'emphasize' : emphasize, 'deemphasize' : deemphasize})}
      	style={{left: left + "px", top: top + "px"}}>
        {ephemeraContent}
      </div>
    )
  }
}

export default EphemeraItem
