import React, { Component } from 'react'
import classNames from 'classnames'

class Snippet extends Component {
	render() {
    const { selected, value, result, isTouchDevice } = this.props
    const effect = this.props.result.effect
    const valueLowerCase = value.toLowerCase()

    const activateCss = (result.css && effect && (result.title.toLowerCase() === valueLowerCase || selected))

		const childComponent =
			<div
        className={classNames("result snippet", {selected: selected, isTouch: isTouchDevice()})}
				key={result.id}
				onClick={(event) => this.props.onClick(event, result)}
				onMouseEnter={this.props.onMouseEnter}
				onMouseLeave={this.props.onMouseLeave}>
          {result.title &&(
            <h1 dangerouslySetInnerHTML={{__html: result.title}} />
          )}
					{result.snippet_content && (
						<div dangerouslySetInnerHTML={{__html: result.snippet_content}} />
          )}
          {activateCss && (
						<div dangerouslySetInnerHTML={{__html: result.css}} />
          )}
			</div>
		return childComponent
	}
}

export default Snippet