import React, { Component } from 'react'

class Post extends Component {
	render() {
		const post = this.props.post
		return (
			<>
			<h1 dangerouslySetInnerHTML={{__html: post.title.rendered}} />
			<div className="post-content" dangerouslySetInnerHTML={{ __html: post.content.rendered }} />
			</>
		)
	}
}

export default Post
