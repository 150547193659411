import React, { Component } from 'react'
import balanceText from 'balance-text'
import Types from './types'
import Store from '../Store'
import classNames from 'classnames'

const post_type_base_url = {
	work: 'works',
	person: 'people',
	page: 'pages',
	snippet: 'snippets',
	post: 'posts',
}

class ContentPane extends Component {
	constructor(props) {
    super(props)
		this.state = {
			post: {},
			isLoading: true
		}
	}

	fetchData = (post_type, post_slug) => {
		this.setState({
			isLoading: true,
		}, () => {
			Store.getPostBySlug(post_type_base_url[post_type], post_slug).then((res) => {
				this.props.onContentLoad(res[0])
				document.title = res[0].title.rendered.replace('&#038;', '&') + " — Denim & Steel"
				this.setState({
					post: res[0],
					isLoading: false,	
				})
			})
		})
	}

	componentDidMount() {
    this._ismounted = true;
    this.fetchData(this.props.type, this.props.slug);
    balanceText();
	}

  componentWillUnmount() {
    this._ismounted = false;
    document.title = "Denim & Steel"
  }

	componentDidUpdate(prevProps) {
		if (this.props.slug !== prevProps.slug) {
			this.fetchData(this.props.type, this.props.slug)
      window.scrollTo(0, 0);
      balanceText();
    }
	}

	render() {
		const { post, device } = this.state
		const { isLoggedIn } = this.props

		return (
			<div className={classNames("content", `post-id-${post.id}`, `post-${this.props.slug}`)}>
				{isLoggedIn && (
          <div className="edit-links">
            <a className="edit admin-link" href={`/wp-admin/post.php?post=${post.id}&action=edit`}>Edit</a>
          </div>
        )}
        <div className="close-items">
          <p className={'close-content'} onClick={this.props.onClick}></p>
          <h5 className={'close-text'} onClick={this.props.onClick} dangerouslySetInnerHTML={{__html: 'close'}}></h5>
        </div>
        {!this.state.isLoading && (
					React.cloneElement(typeof Types[post.slug] !== 'undefined' ? Types[post.slug] : Types[post.type], { post: post, device: this.props.device })
        )}
        {device === 'desktop' && (
				<div className="shield" onClick={this.props.onClick}></div>
        )}
			</div>
		)
	}
}

export default ContentPane