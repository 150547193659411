import React, { Component } from 'react'
import EphemeraItem from './EphemeraItem'

class EphemeraCollection extends Component {
  render() {
  	let ephemeraContent = ""
    let seen = new Set()
    this.props.results.forEach((result, index) => {
      if (result.type === "keyword" && result.related) {
        result.related.forEach((post, index) => {
          if (post.ephemera) {
            post.ephemera.some((ephemera, index) => {
              if (!seen.has(ephemera.id)) {
                ephemeraContent = [ephemeraContent, (<EphemeraItem key={ephemera.id} post={ephemera} emphasize={this.props.hoveredPostID === post.id} deemphasize={this.props.hoveredPostID && this.props.hoveredPostID !== post.id} />)]
                seen.add(ephemera.id)
                // break
                // return true
              }
              return false
            })
          }
        })
      }
      if (result.ephemera) {
        result.ephemera.some((ephemera, index) => {
          if (!seen.has(ephemera.id)) {
            ephemeraContent = [ephemeraContent, (<EphemeraItem key={ephemera.id} post={ephemera} emphasize={this.props.hoveredPostID === result.id} deemphasize={this.props.hoveredPostID && this.props.hoveredPostID !== result.id} />)]
            seen.add(ephemera.id)
            // break
            // return true
          }
          return false
        })
      }
    })

    return (
    	<div className="ephemera">
      	{ephemeraContent}
      </div>
    )
  }
}

export default EphemeraCollection
