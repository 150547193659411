import React, { Component } from 'react'
import { Router, Route, Switch } from 'react-router-dom'
import ContentPane from './components/ContentPane.js'
import Search from './components/Search.js'
import EphemeraCollection from './components/EphemeraCollection'
import SearchResults from './components/SearchResults'
import classNames from 'classnames'
const createHistory = require("history").createBrowserHistory

const history = createHistory()
var isTouchDevice = require('is-touch-device')

const SCROLL_OFFSET = 100

const queryMatches = [
  { device: 'mobile', matchMedia: window.matchMedia('screen and (max-width: 600px)') },
  { device: 'desktop', matchMedia: window.matchMedia('screen and (min-width: 601px)') },
]

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoggedIn: false,
      hoveredPostID: null,
      selectedPostID: null,
      breadCrumbs: [],
      searchTerm: decodeURIComponent(window.location.hash.replace('#', '')),
      results: [],
      queryMatch: '',
      closingRightPane: false,
      defaultContent: null,
      searchLoading: true,
    }
    history.listen((location, action) => {
      //('history event', location)
      this.handlePathChange(location.pathname, location.hash)
    })

    this.closeContentPane = this.closeContentPane.bind(this)
  }

  componentDidMount() {
    for (let query of queryMatches) {
      query.matchMedia.addListener(this.handleResize.bind(this, query))

      if (query.matchMedia.matches) {
        this.setState({
          queryMatch: query
        }, () => {
          this.handleResize(query)
        }) 
      }
    }

    this.getLoggedInStatus()
    this.handlePathChange(window.location.pathname, window.location.hash)
  }

  componentDidUpdate (prevProps) {
    let device = this.state.queryMatch.device;
    let prevScrollPos = window.pageYOffset;
    window.onscroll = function() {
      let currentScrollPos = window.pageYOffset;
      if (true || device === 'mobile') {
        if (currentScrollPos > prevScrollPos && currentScrollPos > 0) {
          // hide menu
          document.getElementsByClassName('searchPane')[0].classList.add('hide');
          prevScrollPos = currentScrollPos;
        } else if (currentScrollPos < (prevScrollPos - SCROLL_OFFSET) || currentScrollPos < SCROLL_OFFSET) {
          // show menu
          document.getElementsByClassName('searchPane')[0].classList.remove('hide');
          prevScrollPos = currentScrollPos;
        }
      }
    }
  }

  componentWillUnmount() {
    for (let query of queryMatches) {
      query.matchMedia.removeListener(this.handleResize)
    }
  }

  handleResize(mediaQuery) {
    if (mediaQuery.matchMedia.matches) {
      this.setState({
          queryMatch: mediaQuery
      })
    }
  }

  handlePathChange(path, hash) {
    console.log("App: handlePathChange:", path, hash)
    const isContentPath = (hash.length === 0) && (path !== "/")
    // const isContentPath = 
    //   path.indexOf("/work/") > -1 || 
    //   path.indexOf("/person/") > -1 || 
    //   path.indexOf("/article/") > -1 || 
    //   path.indexOf("/info/") > -1
    if (isContentPath) {
      this.setState({
        showingContent: isContentPath,
        selectedPostID: isContentPath ? this.state.selectedPostID : null,
      })
    }
  }

  // TODO: showingContent is always false
  closeContentPane() {
    console.log("App: closeContentPane")
    let animationDelay = 450;

    if (this.state.showingContent) {
      this.setState({closingRightPane: true})
      
      setTimeout(() => {
        this.setState({
          showingContent: false,
          closingRightPane: false,
        }, () => {
          if (!this.state.showingContent) {
            history.push('/' + (this.state.searchTerm.length > 0 ? `#${this.state.searchTerm}` : ''))
          }
        })
      }, animationDelay);
    }

  }

  handleSelectionChange(e, value) {
    e.stopPropagation()

    if (value.type === 'post') {
      history.push(`/article/${value.slug}`)
    } else if (value.type === 'page') {
      history.push(`/${value.slug}`)
    } else if (value.type !== 'snippet') {
      history.push(`/${value.type}/${value.slug}`)
    }

    this.setState({
      selectedPostID: value.id
    })

    let breadCrumbs = this.state.breadCrumbs
    let searchTerm = this.state.searchTerm

    if (value.type === 'snippet' && value.effect) {
      searchTerm = value.title.toLowerCase();
    }

    if (!breadCrumbs.includes(searchTerm)) {
      this.setState({
        breadCrumbs: [searchTerm, ...this.state.breadCrumbs]
      })
    }
  }

  handleHoveredPostChange(post) {
    this.setState({
      hoveredPostID: post ? post.id : null
    });
  }

  handleClear() {
    console.log('App: handleClear')
    this.setState({
      searchTerm: '',
      results: [],
      selectedPostID: null,
    })
    this.closeContentPane()
  }

  handleClearContent() {
    this.setState({
      searchTerm: '',
      results: [],
      selectedPostID: null,
    })
  }

  getLoggedInStatus() {
    fetch('/wp-admin/admin-ajax.php?action=is_logged_in', { credentials: 'include' }) // { credentials: 'same-origin' } is more secure once we're in production.
      .then((res) => res.json())
      .then((res) => {
        this.setState({
          isLoggedIn: res.data
        })
      })
      .catch(() => {
        this.setState({
          isLoggedIn: false
        })
      })
  }

  handleKeywordChange() {
    this.setState({
      searchLoading: true,
    })
  }

  updateResults(state) {
    const { searchTerm, results } = state

    if (this.state.showingContent && results.length > 0) {
      // this only happens if a permalink is used
      this.setState({
        selectedPostID: results[0].id
      })
    } else {
      if (searchTerm.length > 0) {
        history.push(`/#${searchTerm}`)
      }
    }

    this.setState({
      searchTerm: searchTerm,
      results: results,
      searchLoading: false,
    })
  }

  updateBreadCrumbs(term) {
    if (this.state.breadCrumbs.indexOf(term) < 0) {
      this.setState({
        breadCrumbs: [term, ...this.state.breadCrumbs]
      })
    }
  }

  // Permalink handler
  handleContentLoad(post) {
    console.log("App: handleContentLoad: ", post.title.rendered)
    if (this.state.searchTerm.length === 0) {
      this.setState({
        searchTerm: post.title.rendered.slice(0, 32).replace('&#038;', '&')
      })
    }
  }

  render() {
    const { showingContent, isLoggedIn, closingRightPane} = this.state
    const handleContentLoad = this.handleContentLoad.bind(this)
    let device = this.state.queryMatch.device

    const contentProps = {
      isLoggedIn: isLoggedIn,
      onClick: this.closeContentPane,
      device: device,
      onContentLoad: handleContentLoad,
    }

    return (
      <Router history={history}>
        <div className={classNames("app", {
          "showing-content": showingContent,
          "showing-nav": !showingContent,
          "closing-right-pane": closingRightPane,
          "has-results": this.state.results.length > 0,
        })}>
          {!isTouchDevice() && (
          <EphemeraCollection results={this.state.results} hoveredPostID={this.state.hoveredPostID} />
          )}
          <div className="searchPane" onClick={this.closeContentPane.bind(this)}>
            <Search
              searchValue={this.state.searchTerm}
              handleClear={this.handleClear.bind(this)}
              handleClearContent={this.handleClearContent.bind(this)}
              breadCrumbs={this.state.breadCrumbs} 
              updateBreadCrumbs={this.updateBreadCrumbs.bind(this)}
              updateResults={this.updateResults.bind(this)}
              closeContentPane={this.closeContentPane.bind(this)}
              device={device}
              isTouchDevice={isTouchDevice}
              showingContent={showingContent}
              isLoading={this.state.searchLoading}
              handleKeywordChange={this.handleKeywordChange.bind(this)} />
          </div>
          <div className="leftPane" onClick={this.closeContentPane.bind(this)}>
            <SearchResults
              searchValue={this.state.searchTerm}
              results={this.state.results}
              loadingResults={this.state.searchLoading}
              value={this.state.searchTerm}
              device={device}
              direction={'vertical'}
              showingContent={this.state.showingContent}
              handleSelectionChange={this.handleSelectionChange.bind(this)}
              handleHoveredPostChange={this.handleHoveredPostChange.bind(this)}
              handleClick={this.closeContentPane}
              selectedPostID={this.state.selectedPostID}
              hoveredPostId={this.state.hoveredPostID}
              isTouchDevice={isTouchDevice}
              />
          </div>
          {device === 'mobile' && (
            <div className="leftPane-mobile" onClick={this.closeContentPane.bind(this, false)}>
              <SearchResults
                searchValue={this.state.searchTerm}
                results={this.state.results}
                loadingResults={this.state.searchLoading}
                value={this.state.searchTerm}
                device={device}
                direction={'horizontal'}
                showingContent={this.state.showingContent}
                handleSelectionChange={this.handleSelectionChange.bind(this)}
                handleHoveredPostChange={this.handleHoveredPostChange.bind(this)}
                handleClick={this.closeContentPane}
                selectedPostID={this.state.selectedPostID}
                hoveredPostId={this.state.hoveredPostID}
                isTouchDevice={isTouchDevice}
                />
            </div>
          )}
          <div className="rightPane">
            <Switch>
              <Route path="/work/:slug" render={({ match }) => <ContentPane slug={match.params.slug} type={'work'} {...contentProps} />} />
              <Route path="/person/:slug" render={({ match }) => <ContentPane slug={match.params.slug} type={'person'} {...contentProps} />} />
              <Route path="/article/:slug" render={({ match }) => <ContentPane slug={match.params.slug} type={'post'} {...contentProps} />} />
              <Route path="/:slug" render={({ match }) => <ContentPane slug={match.params.slug} type={'page'} {...contentProps} />} />
            </Switch>
          </div>
          {isLoggedIn && (
            <div className="admin-links">
              <a className="dashboard admin-link" href="/wp-admin">Admin Dashboard</a>
            </div>
          )}
        </div>
      </Router>
    )
  }
}

export default App
