import React, { Component } from 'react'
import SearchResult from './SearchResult'
import classNames from 'classnames'
import Store from '../Store'
import { Link } from 'react-router-dom'

class SearchResults extends Component {
  constructor(props) {
    super(props)
    this.selectedResultRef = React.createRef();
    this.listRef = React.createRef();
    this.state = {
      defaultContent: null,
    }
  }

  componentDidMount() {
    Store.getPostBySlug('snippets', 'introduction')
      .then(res => {
        this.setState({
          defaultContent: { style: '', ...res[0] }
        })
      })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.direction === 'horizontal' && this.listRef.current && this.selectedResultRef.current && this.selectedResultRef.current.props.result.type !=="snippet") {
      if (this.props.selectedPostID !== prevProps.selectedPostID) {
        const resultList = this.listRef.current;
        const result = this.selectedResultRef.current.resultRef.current;
        const resultWidth = result.clientWidth;
        const resultListWidth = resultList.clientWidth;
        const resultCenterOffset = (resultListWidth-resultWidth)/2;
        resultList.scrollLeft = result.offsetLeft - resultCenterOffset;
      }
    }
    return true
  }

  handleResultMouseEnter(post) {
    this.props.handleHoveredPostChange(post)
  }

  handleResultMouseLeave(post) {
    this.props.handleHoveredPostChange(null)
  }

  render() {
    const { results, handleSelectionChange, device, showingContent, selectedPostID, searchValue, hoveredPostId, value, direction, handleClick, isTouchDevice } = this.props

    if (results.length > 0 && searchValue !== ' ') {
      return (
        <div className={classNames("results", {isTouch: isTouchDevice()})} ref={this.listRef}>
            { results.map((result, index) => {
              if (result.type === "keyword" && result.related) {
                return result.related.map((post, index) => {
                  if(post.type === "snippet" && direction === "horizontal") {
                    return null;
                  }
                  else {
                    return (
                      <SearchResult
                        key={post.id}
                        result={post}
                        device={device}
                        showingContent={showingContent}
                        selected={selectedPostID === post.id}
                        onClick={handleSelectionChange}
                        onMouseEnter={this.handleResultMouseEnter.bind(this, post)}
                        onMouseLeave={this.handleResultMouseLeave.bind(this, post)}
                        active={hoveredPostId === post.id}
                        value={value}
                        handleClick={handleClick}
                        inactive={hoveredPostId && hoveredPostId !== post.id}
                        ref={selectedPostID === post.id ? this.selectedResultRef : undefined}
                        isTouchDevice={isTouchDevice}
                      />
                    )
                  }
                })
              } else if (result.type !== "keyword") {
                if(result.type === "snippet" && direction === "horizontal") {
                  return null;
                }
                else {
                  return (
                    <SearchResult
                      key={result.id}
                      result={result}
                      device={device}
                      showingContent={showingContent}
                      selected={selectedPostID === result.id} 
                      value={value}
                      handleClick={handleClick}
                      onClick={handleSelectionChange} 
                      onMouseEnter={this.handleResultMouseEnter.bind(this, result)} 
                      onMouseLeave={this.handleResultMouseLeave.bind(this, result)}
                      emphasize={hoveredPostId === result.id}
                      deemphasize={hoveredPostId && hoveredPostId !== result.id}
                      ref={selectedPostID === result.id ? this.selectedResultRef : undefined}
                      isTouchDevice={isTouchDevice}
                    />
                  )
                }
              }
              else {
                return null;
              }
            })}
        </div>
      )
    } else if (this.props.loadingResults) {
      return (
        <div className="results">
        </div>
        )
    } else if (searchValue && searchValue.length > 0) {
      // Search box is not empty, but still there are no results
      return (
        <div className="results">
          <div className="empty-results">Oops! We didn't find anything 👀</div>
        </div>
        )
    } else {
      // Search box is empty
      return this.state.defaultContent && window.location.pathname === '/' && (
        <div className="results intro">
          <div>
            <div className={`result${this.state.defaultContent.style}`} dangerouslySetInnerHTML={{ __html: this.state.defaultContent.acf.snippet_content }} />
            <Link to="privacy-policy" className="privacy-policy">Privacy Policy</Link>
          </div>
        </div>
      )
    }
  }
}

export default SearchResults